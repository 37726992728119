/* Ensure the outer container for the entire comment section is responsive */
.comments-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  /* Ensure the section doesn’t exceed the width of its parent */
  margin: 0;
  padding: 0 10px;
  /* Optional: Add padding if necessary */
  box-sizing: border-box;
  overflow-x: hidden;
  /* Prevent horizontal scrolling */
}

/* Individual comment container */
.comment-container {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
}

/* Ensure that long content inside each comment wraps correctly */
.comment-content {
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
  white-space: pre-wrap !important;
  max-width: 100%;
  box-sizing: border-box;
}

/* Ensure images within the comments or editor are responsive */
.react-quill-container .ql-editor img,
.comment-content img {
  max-width: 100% !important;
  height: auto !important;
  display: block;
  /* Makes sure the image doesn’t overflow its container */
  margin: 10px 0;
  /* Adds space around images */
}

/* Quill editor container */
.react-quill-container .ql-container {
  border: 1px solid #ccc;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
}

/* Quill editor content */
.react-quill-container .ql-editor {
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
  height: auto !important;
  min-height: 100px !important;
  padding: 10px;
  /* Adds padding to the content for better readability */
}

/* Ensure the submit button is full-width and responsive */
.button-fullwidth {
  width: 100%;
  max-width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
}

.button-fullwidth:hover {
  background-color: #0056b3;
}