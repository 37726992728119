.item-list-container {
  max-height: 715px;
  overflow-y: auto;
  padding: 10px;
}

.item-list {
  list-style-type: none;
  padding: 0;
}

.item {
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 13.5px;
}

.item-card {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  white-space: normal;
  /* Allow text to wrap */
  word-wrap: break-word;
  /* Handle long words */
  overflow-wrap: break-word;
  /* Better compatibility */
  word-break: break-word;
}

/* Priority based styling */
.item-priority-P0 {
  border-left: 4px solid red;
}

.item-priority-P1 {
  border-left: 4px solid orange;
}

/* Status based styling */
.item-status-new {
  background-color: #ffffff;
}

.item-status-in-progress {
  background-color: #fffcc6;
}

.item-status-fixed-untested {
  background-color: #ccedcd;
}

.item-status-fixed-tested {
  background-color: #88f08b;
}

.item-status-obsolete {
  background-color: #cecdce;
}

.item-list li.selected {
  font-weight: bold;
}

.itemsContainer {
  width: 960%;
}

.item-component-container {
  max-width: 600px;
  /* Adjust width as needed */
  margin: 0 auto;
  /* Center the component */
  padding: 20px;
  background-color: #f9f9f9;
  /* Light background for the component */
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.edit-mode-textarea {
  width: 100%;
  /* Adjust width to fit your layout */
  height: 150px;
  /* Adjust height as needed */
  padding: 10px;
  border: 1px solid #ddd;
  /* Example styling */
  border-radius: 4px;
  /* Example styling */
}

.item-component-container h3 {
  color: #333;
  /* Dark color for the title */
  margin-bottom: 15px;
}

.item-description {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #fff;
  /* White background for description */
  border: 1px solid #ddd;
  /* Border for the description box */
  border-radius: 5px;
}

.copy-button {
  /* border: 2px solid #00000000; */
  /* Black border */
  /* position: relative; */
  /*width: 24px; /* or your desired size */
  /*height: 24px; /* or your desired size */
  /* background-color: transparent; */
  cursor: pointer;
  /* padding: 10px; */
  font-size: 10px;
}

.backlog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.backlog-title {

  font-size: 1.17rem;
  font-weight: bold;
}
.add-item-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  /* Center the modal */
  padding: 15px;
  background-color:white;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.add-item-container h3 {
display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
  text-align: center;
}
.add-tasks-title{
  margin-top: 3px!important;
  font-size:1.17rem!important ;
  font-weight:bold!important
}

.add-item-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
  margin-left: 5px;
}

/* .add-item-container .form-input {
  width: 100%;
  padding:8px 8px ;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
  margin-left: 5px;
  margin-right: 5px;

} */

.add-item-container textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  resize: none;
}

.add-item-container button[type="submit"] {
   padding: 8px 8px; 
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 40px;
    width: 100%;
    margin-left: -1px;
    margin-right: 8px;

}

.add-item-container button[type="submit"]:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: -10px;
}

@media (max-width: 600px) {
  .add-item-container {
    padding: 10px;
  }

  .add-item-container .form-input,
  .add-item-container textarea,
  .add-item-container button[type="submit"] {
    width: 100%;
  }
}
.add-item-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #121213;
  margin-top:-1.5px;
  margin-right:5px;
  
}

.add-item-button:hover {
  color: #0056b3;
  background-color: transparent;
}
.form-inputs-description{
  width:100%;
  overflow: hidden;
  height:43px;
  margin-left: -1px;
  margin-right:8px ;
    gap: 1rem;
} 
.descriptions{
  height: 44px!important;
  width: 100%!important;
  overflow: hidden;
  align-items: center;
margin-left:-1px ;
display: flex;
  gap: 1rem;
margin-right: 8px;

}
.add-tasks-button{
text-transform: capitalize;
}