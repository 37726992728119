/* Common styles for both HashtagManager and AddItem */
.manager-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin-bottom: 15px;
    max-height: 50%;
      /* Example to limit height */
      overflow-y: auto; /* Space between components */
  }
  
  .manager-container h3 {
    margin-bottom: 15px;
  }
  
  .manager-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }
  
  .form-row {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
  }
  
  .form-input {
    flex-grow: 1;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    color: #007bff;
  }
  
  /* Styles specific to HashtagManager */
  .hashtag-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    max-height: 350px;
  }
  
  .hashtag-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 13.5px;
    margin-right: 10px;
  }
  
  .hashtag-item.selected {
    background-color: #b3e5fc;
    font-weight: bold;
  }
  
  .loading, .error {
    color: #d32f2f;
    margin: 10px 0;
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .form-row {
      flex-direction: column;
    }
  
    .manager-container .form-input,
    .manager-container button[type="submit"] {
      width: 100%;
    }
  }
  
.hashtag-and-item-manager {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* Prevents overflow */
}

@media (max-width: 600px) {
  .hashtag-and-item-manager {
    flex-direction: column;
    /* Ensures AddItem is on top */
  }
}

