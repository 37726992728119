.item-component-container {
  max-width: 600px; /* Adjust width as needed */
  margin: 0 auto; /* Center the component */
  padding: 20px;
  background-color: #f9f9f9; /* Light background for the component */
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /*Subtle shadow for depth */
}
.edit-mode-textareas {
width:100%;
  height: 150px!important;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 1px;


}
.item-component-container h3 {
  color: #333; /* Dark color for the title */
  margin-bottom: 15px;
}

.item-description {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #fff; /* White background for description */
  border: 1px solid #ddd; /* Border for the description box */
  border-radius: 5px;
}
.edit-areas{
  /* margin-left:0px;
  border-radius: 4px;
  padding-left: 5px;
  padding-right:11px;*/
  width:100%!important;
  position: relative;
    left: 0;
   
    /* Ensure no extra offset */
   margin-right:2px!important ;
   margin-left: -10px!important;
  margin-bottom: 1px!important;
 
    ;
}
.copy-buttons {
  /* border: 2px solid #00000000; */
  /* position: relative; */
  /* width: 24px;
  height: 24px; */
  /* background-color: transparent; */
  cursor: pointer;
  margin-left: 0px;
  background-color: transparent;
  color: #333;
  margin-right: 0px;
}
.copy-buttons:hover {
  color: rgb(71, 71, 221);
  background-color: transparent;
}
.share-button {
  margin-left: 0px;
  margin-right: 0px;
  padding-right: 0px;
}
/* Styles for CommentList and CommentForm are inherited from their respective CSS files */
.item-title {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  width: 100%;
  display: block;
  margin-bottom: 15px;
  font-size: 1.17em;
  font-weight: bold;
}
.item-description {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  /* Forces breaking of long words */
  display: block;
  margin-bottom: 15px;
  /* Remove the following styles to eliminate the box */
  padding: 0;
  border: none;
  background-color: transparent;
  border-radius: 0;
}
.button-linkstyle {
  text-transform: lowercase;
  background-color: transparent;
  font-weight: 600;

}
.button-linkstyle:hover {
  text-transform: lowercase;
  background-color: transparent;
  
}
.button-linkstyles{
  text-transform: lowercase;
    background-color: transparent;
    font-weight: 597;
    margin-right: 3px!important;
color:#007bff;

}
.button-linkstyles:hover {
  text-transform: lowercase;
  background-color: transparent;

}
.task-id{
  margin-top: -18px!important ;
  
}
